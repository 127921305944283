<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    :max-width="dialogWidth"
    persistent
    overlay-opacity="0.97"
    @keydown.esc="cancel"
  >
    <v-card
      tile
      elevation="0"
      class="d-flex flex-column noscroll charcoal"
      v-touch:swipe.left="cancel"
    >
      <BaseCloseDialogHeader
        heading="Add Measurement"
        @cancel="$emit('cancel')"
      />
      <v-sheet rounded class="mx-2 pa-2 charcoal silver--text mb-2">
        <v-card-text :class="textSizeXSmall + ' px-0 pt-2 pb-6'">
          <v-icon class="silver--text ml-n1">
            mdi-information
          </v-icon>
          Select the measure type from the drop-down below, read the
          instructions (if any), enter the measurement either in metric or
          imperial units, and then click
          <span class="progressActive--text">Save</span> button.
        </v-card-text>
      </v-sheet>
      <v-sheet
        rounded
        class="mx-2 pa-2 charcoal charcoalTileMenu silver--text mb-2"
      >
        <v-card-text class="pa-0 mt-2">
          <v-row dense>
            <v-col cols="12">
              <v-select
                height="70"
                solo
                dark
                label="Select Measurement"
                v-model="measure"
                chips
                return-object
                prepend-inner-icon="mdi-tape-measure"
                :items="measureList"
                item-text="name"
                item-value="id"
                @change="selectRow"
                color="progressActive"
                item-color="black"
                background-color="charcoal lighten-2"
                :menu-props="{
                  closeOnClick: true,
                  maxHeight: 350,
                  transition: 'fab-transition'
                }"
              >
                <template
                  v-slot:selection="{
                    item,
                    index,
                    selected,
                    disabled,
                    remove
                  }"
                >
                  <v-chip
                    class="silver lighten-3 pa-4 text-h6"
                    :key="index"
                    :input-value="item.value"
                    :disabled="disabled"
                  >
                    <span class="black--text"> {{ item.name }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-sheet
            v-if="selectedMeasure.instructions"
            rounded
            class="py-2 transparent mb-2"
          >
            <div
              :class="textSizeXSmall + ' silver--text'"
              style="white-space: pre-line"
              v-html="selectedMeasure.instructions"
            />
          </v-sheet>
          <v-sheet rounded class="transparent pt-4">
            <template v-if="measure.name != 'Height'">
              <v-row dense class="px-1">
                <v-col cols="6">
                  <span class="paper--text">
                    {{ 'Imperial' + showUnit('imperial') }}
                  </span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-2"
                    color="progressActive"
                    height="60"
                    dark
                    :error-messages="valueErrors"
                    :label="'Imperial' + showUnit('imperial')"
                    ref="imperial"
                    v-model="imperial"
                    required
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    :disabled="this.measure.id == null"
                    prepend-inner-icon="mdi-tape-measure"
                    @input="calculateMetric()"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6">
                  <span class="paper--text">
                    {{ 'Metric' + showUnit('metric') }}
                  </span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-2"
                    color="progressActive"
                    height="60"
                    dark
                    :error-messages="valueErrors"
                    :label="'Metric' + showUnit('metric')"
                    ref="metric"
                    v-model="metric"
                    required
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    :disabled="this.measure.id == null"
                    prepend-inner-icon="mdi-tape-measure"
                    @input="calculateImperial()"
                    @focus="$event.target.select()"
                  />
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row dense class="px-1">
                <v-col cols="6">
                  <span class="paper--text">
                    Feet
                  </span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-2"
                    color="progressActive"
                    height="60"
                    dark
                    :error-messages="valueErrors"
                    label="Feet"
                    v-model="feet"
                    ref="feet"
                    required
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    :disabled="this.measure.id == null"
                    prepend-inner-icon="mdi-tape-measure"
                    @input="calculateMetric()"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6">
                  <span class="paper--text">
                    Inches
                  </span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-2"
                    color="progressActive"
                    height="60"
                    dark
                    :error-messages="valueErrors"
                    label="Inches"
                    ref="inches"
                    v-model="inches"
                    required
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    :disabled="this.measure.id == null"
                    prepend-inner-icon="mdi-tape-measure"
                    @input="calculateMetric()"
                    @focus="$event.target.select()"
                  />
                </v-col>
              </v-row>
              <v-row dense class="px-1">
                <v-col cols="12">
                  <span :class="textSize + ' silver--text'">OR</span>
                </v-col>
              </v-row>
              <v-row dense class="px-1">
                <v-col cols="6">
                  <span class="paper--text">
                    {{ 'Metric' + showUnit('metric') }}
                  </span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-2"
                    color="progressActive"
                    height="60"
                    dark
                    :error-messages="valueErrors"
                    :label="'Metric' + showUnit('metric')"
                    ref="metric"
                    v-model="metric"
                    required
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    :disabled="this.measure.id == null"
                    prepend-inner-icon="mdi-tape-measure"
                    @input="calculateImperial()"
                    @focus="$event.target.select()"
                  />
                </v-col>
              </v-row>
            </template>
            <v-row dense class="px-1">
              <v-col cols="4" />
              <v-col cols="8">
                <v-datetime-picker
                  filled
                  class="paper--text"
                  color="silver"
                  label="Date & Time When Measured"
                  v-model="datetime"
                  :text-field-props="textFieldProps"
                  :date-picker-props="dateProps"
                  :time-picker-props="timeProps"
                  time-format="HH:mm"
                  date-format="yyyy-MM-dd"
                >
                  <template v-slot:dateIcon>
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
            </v-row>
          </v-sheet>
        </v-card-text>
      </v-sheet>
      <v-spacer />
      <v-card-actions class="pa-0 ">
        <v-btn
          :disabled="
            !(this.imperial > 0 || this.metric > 0) ||
              this.selectedMeasure.id == null
          "
          block
          height="70"
          title="Done For The Day"
          class="black--text"
          color="progressActive"
          @click="saveMeasurement()"
        >
          <v-icon x-large color="black">
            mdi-checkbox-multiple-marked-circle
          </v-icon>
          <span class="text-h5">Save</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import moment from 'moment'

export default {
  components: {},
  name: 'AddMeasurement',
  mixins: [validationMixin, util],
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar',
        dark: true
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      switchNum: 0,
      loaded: false,
      metric: 0,
      imperial: 0,
      feet: 0,
      inches: 0,
      measure: {},
      search: '',
      selectedMeasure: {},
      measureList: [],
      measurementsList: [],
      datetime: null,
      selectedDateTime: null
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  validations: {
    value: {
      required
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueErrors() {
      const errors = []
      if (!this.$v.value.$dirty) return errors
      !(this.imperial > 0 || this.metric > 0) &&
        errors.push('Value is required.')
      return errors
    },
    ...appConfig
  },
  watch: {
    show: function(val) {
      this.$store.dispatch('session/toggleModal', val)
      if (val) {
        let formatted = moment().format('yyyy-MM-DD hh:mm')
        this.datetime = formatted

        this.loadTypes()
      } else this.loaded = false
    }
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        this.cancel()
      }
    },
    showUnit(what) {
      if (this.selectedMeasure == '' || this.selectedMeasure == null) return ''
      if (what == 'imperial') return ' (' + this.selectedMeasure.imperial + ')'
      return ' (' + this.selectedMeasure.metric + ')'
    },
    calculateImperial() {
      let rounding = Math.pow(10, this.selectedMeasure.rounding)
      this.imperial = this.metric / this.selectedMeasure.factor
      this.feet = Math.floor(this.imperial / 12)
      this.inches = Math.floor(this.imperial % 12)
      this.imperial = Math.floor(this.imperial * rounding) / rounding
    },
    calculateMetric() {
      let rounding = Math.pow(10, this.selectedMeasure.rounding)
      if (this.measure.name == 'Height') {
        this.imperial = this.twoDigits(
          parseInt(this.feet * 12) + parseFloat(this.inches)
        )
      } else {
        this.feet = Math.floor(this.imperial / 12)
        this.inches = Math.floor(this.imperial % 12)
      }
      this.metric = this.imperial * this.selectedMeasure.factor
      this.metric = Math.floor(this.metric * rounding) / rounding
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    },
    reset() {
      this.metric = 0
      this.imperial = 0
      this.inches = 0
      this.feet = 0
      this.selectedDateTime = ''
    },
    selectRow() {
      this.reset()
      this.selectedMeasure = this.measure
    },
    saveMeasurement() {
      let dateObj = new Date(this.datetime)
      this.selectedDateTime = this.formatDateTime(dateObj)
      return axios
        .post(this.baseURL + '/measurements', {
          measurementid: this.selectedMeasure.id,
          metric: this.metric,
          imperial: this.imperial,
          measured: this.selectedDateTime
        })
        .then(response => {
          if (response.status == 200) {
            this.reset()
            this.toast('Measurement was succesfully added.')
            window.postMessage('measurement_added', '*')
            this.$emit('done')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadTypes() {
      {
        return axios
          .get(this.baseURL + '/measurements/all', {})
          .then(response => {
            if (response.status == 200) {
              this.measureList = response.data.data
              this.selectedMeasure = this.measureList[0]
              this.measure = this.selectedMeasure
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
